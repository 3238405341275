import Users from "@/api/User";

export default {
    name: "Auth",
    set_user(user_info, token = null) {
        if (token != null) {
            window.localStorage.setItem('user-token', token);
        }
        window.localStorage.setItem('user-info', JSON.stringify(user_info));
        window.dispatchEvent(new CustomEvent('user-logged-event', {
            detail: {user: user_info}
        }));
    },
    set_token(token) {
        window.localStorage.setItem('user-token', token);
    },
    user() {
        if (window.localStorage.getItem('user-info')) {
            let user = JSON.parse(window.localStorage.getItem('user-info'));
            user.token = window.localStorage.getItem('user-token');
            return user;
        }
        return '';
    },
    token() {
        return window.localStorage.getItem('user-token');
    },
    refresh() {
        let newToken = Users.refresh_token().toString();
        window.localStorage.setItem('user-token', newToken);
        return newToken;
    },
    logout() {
        window.localStorage.removeItem('user-token');
        window.localStorage.removeItem('user-info');
        window.localStorage.removeItem('carrinho');
        window.localStorage.removeItem('user_buy');
        window.localStorage.removeItem('last_event');

        window.dispatchEvent(new CustomEvent('user-logout-event'));
        return true;
    }
}
